// Portal Styles:

// _____________________________________________________________

import styled from 'styled-components'
import theme from '../../gatsby-plugin-theme-ui'

// Begin Styles
// _____________________________________________________________

export const Portal = styled.div``