import * as React from 'react'

const Hide = () => (
  <svg viewBox="0 0 94.99 79.77">
    <title>Hide</title>
    <path d="M94.38,40.18a65.51,65.51,0,0,0-20-17.11l-9.8,9.8a19,19,0,0,1,2.3,9.2,19.25,19.25,0,0,1-19.3,19.3,19,19,0,0,1-9.2-2.3l-6.61,6.61A56.21,56.21,0,0,0,47.58,68c26.19,0,42.19-18,46.9-24A3.48,3.48,0,0,0,94.38,40.18Z" />
    <path d="M47.48,56.07a14,14,0,0,0,14-14,15,15,0,0,0-1-5.2L42.27,55.07A14.89,14.89,0,0,0,47.48,56.07Z" />
    <path d="M84.38,1a3.34,3.34,0,0,0-4.7,0L62.48,18.18a53.24,53.24,0,0,0-14.9-2.11c-26.2,0-42.2,18-46.9,24a3,3,0,0,0,0,3.8A66,66,0,0,0,20,60.57L6.48,74.07a3.32,3.32,0,0,0,0,4.7,3.25,3.25,0,0,0,2.4,1,3.23,3.23,0,0,0,2.39-1L84.48,5.57A3.29,3.29,0,0,0,84.38,1Zm-56.2,41.1a19.31,19.31,0,0,1,19.3-19.3,19,19,0,0,1,8.5,2l-4.1,4.1a15.59,15.59,0,0,0-4.4-.69,14,14,0,0,0-14,14,15.68,15.68,0,0,0,.7,4.39l-4.1,4.11A21.23,21.23,0,0,1,28.18,42.07Z" />
  </svg>
)

export default Hide
